import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import MarketBadge from '../components/MarketBadge'
import Markets from '../components/featured/markets'

import styled from 'styled-components'

const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const Business = (props) => {


 

  const renderPlayer = (edge, index) => {

    const isLogo = () => {
      if (edge.node.imageFile !== 'none' && edge.node.imgTWhite === false) {
        return (
          <img style={{marginBottom: 0, maxWidth: 100, marginRight: 20}} variant="top" src={`/images/business/${edge.node.imageFile}`} alt={`${edge.node.name} logo`} />
        )
      } 
      if (edge.node.imageFile !== 'none' && edge.node.imgTWhite === true) {
        return (
          <img style={{marginBottom: 0, maxWidth: 110, padding: 10, background: '#663499', marginRight: 20}} variant="top" src={`/images/business/${edge.node.imageFile}`} alt={`${edge.node.name} logo`} />
        )
      } 
    }

  

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>
            <BS.Row>
              <BS.Col sm={6}>
              < Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}></Link> < Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`} >{isLogo()} {edge.node.name}</Link>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
              <MarketBadge market={edge.node.what}></MarketBadge> <span style={{marginLeft: 20}}>funds raised: <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.totalRaised)}</b></span>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Startups and Scale-ups Ranked By Unicorn Alert</title>
        <meta name="title" content="Startups and Scale-ups Ranked By Unicorn Alert"></meta>
        <meta name="description" content='They are on fire! Startups and Scale-ups ranked by total amount of funds raised.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Business List</BS.Breadcrumb.Item>
      </BreadcrumbLite>

            <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>{props.data.allBusiness.totalCount} fastest growing tech businesses</h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Startups and Scale-ups ranked by total amount of funds raised</h2>
                </BS.Col>
            </BS.Row>


          <BS.Row>
            <BS.Col sm={8} style={{marginTop: -5}}>

            {props.data.allBusiness.edges.map(renderPlayer)}
            </BS.Col>

      
          <BS.Col sm={4}>


            <Markets />

          </BS.Col>
          </BS.Row>
       

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allBusiness(sort: {order: DESC, fields: totalRaised}, limit: 400) {
    edges {
      node {
      id
      name
      currency
      totalRaised
      what
      imgTWhite
      imageFile
    
      
    }
  }
  totalCount
}
}
`

export default Business